import React, { useEffect, useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import Lefticon from "../../../assets/image/lefticon.svg";
import Righticon from "../../../assets/image/righticon.svg";
import "./Dashboard.css";
import { RiVideoAddFill } from "react-icons/ri";
import { BsFillSaveFill, BsPatchCheckFill } from "react-icons/bs";
import sample from "assets/sample.mp4";
import { MdOutlineTimelapse } from "react-icons/md";
import { MdNotificationsActive } from "react-icons/md";
import {
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import JolPlayer from "jol-player";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper";
import DashboardVcard from "components/Card/DashboardVcard";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GetAllInterviewAdmin,
  getDashboardReq,
  getStudentDashboardReq,
} from "../../../axios/apis";
import { Spinner } from "react-bootstrap";
import { CardData } from "../data";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";
import { Toaster } from "react-hot-toast";

export const Dashboard = () => {
  const [viewWidth, setViewWidth] = useState("");
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [interview, setInterView] = useState([]);
  const navigate = useNavigate();
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllInterviewAdmin();
      setInterView(response?.data?.interviews);
      // console.log(response.data.interviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setViewWidth(window.innerWidth);
  }, [window.innerWidth]);

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const res = await getDashboardReq();
      setDashboardData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getStudentDashboard = async () => {
    try {
      setLoading(true);
      const res = await getStudentDashboardReq();
      setDashboardData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const params = useParams();
  useEffect(() => {
    if (params?.id) {
      Cookies.set("token", params.id);
      Cookies.set("role", "interviewee");
    }
    // window.location.reload()
    if (window.location.pathname === "/interviewee/dashboard") {
      getDashboardData();
      GetAllInterviews();
    }
    if (window.location.pathname === "/college-student/dashboard") {
      getStudentDashboard();
      GetAllInterviews();
    }
  }, []);

  // console.log(interview)
  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className=" scroller">
          <div
            className="container px-0
            "
          >
            {/* <h5 className="text-center my-1  pb-2">
              “A person who never made a mistake never tried anything new."
              —Albert Einstein
            </h5> */}
            <div className="card1-container mt-3">
              <Link
                to={"/interviewee/practice-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Total Mock Interviews</h6>
                  <h6>{dashboardData?.totalInterviews}</h6>
                </div>
                <div className="col-2">
                  <BsPatchCheckFill size={26} color=" #521986" />
                </div>
              </Link>
              <Link
                to={"/interviewee/my-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Total interview attempts</h6>
                  <h6>{dashboardData?.attemptedInterviews}</h6>
                </div>
                <div className="col-2">
                  <FaUserFriends size={26} color=" #521986" />
                </div>
              </Link>
              {/* <div className="dashboard-card1 ">
                  <div className="col-10">
                    <h6 className="card1-head">My Company Interviews</h6>
                    <h6>5/10</h6>
                  </div>
                  <div className="col-2">
                    <TiTick size={26} color=" #521986" />
                  </div>
                </div> */}
              <Link
                to={"/interviewee/my-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Total Time Spent</h6>
                  <h6>{Math.round(dashboardData?.totalMinutes)} Minutes</h6>
                </div>
                <div className="col-2">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </Link>
              <Link
                to={"/interviewee/my-interview"}
                className="dashboard-card1 "
              >
                <div className="col-10">
                  <h6 className="card1-head">Average Interview Score</h6>
                  <h6>{Math.round(dashboardData?.averageScore)}/100</h6>
                </div>
                <div className="col-2">
                  <RiVideoAddFill size={26} color=" #521986" />
                </div>
              </Link>
              {/* <div className="dashboard-card1 ">
                  <div className="col-10">
                    <h6 className="card1-head">Saved Videos</h6>
                    <h6>150</h6>
                  </div>
                  <div className="col-2">
                    <BsFillSaveFill size={26} color=" #521986" />
                  </div>
                </div> */}

              {/* <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">ATS Complaint Resume</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">Apply Multiple Jobs</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">Career Path</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-gradien">
                <div className="col-9">
                  <h6 className="card1-head">Upgrade your plan</h6>
                  <h6>₹1500- Only</h6>
                </div>
                <div className="col-2 slider-icon">
                  <MdOutlineTimelapse size={26} color=" #521986" />
                </div>
              </div> */}
            </div>
            <div className="dashboard-sec-2 ">
              <div className="dashboard-sec-2-1 w-100-820">
                <div className="overview-video ">
                  <YellowHeading className=" p-3 d-none-525">
                    How to use this Tool?
                  </YellowHeading>
                  <div className="video-player dashboard mx-4 ">
                    {" "}
                    <video
                      className="w-100 pb-4"
                      src="https://interview-bot.s3.ap-south-1.amazonaws.com/interview+bot/Interview+Pro/product+demonstration/Interview+Pro.mp4"
                      alt="Tool video"
                      controls
                    />
                  </div>
                </div>
              </div>
              <div className="dashboard-sec-2-2 d-none-820">
                <div className="reminders">
                  <div className="d-flex justify-content-between align-content-center p-3">
                    <h4>Reminders</h4>
                    <MdNotificationsActive size={26} color=" #521986" />
                  </div>
                  <div className="reminder-box-scroll">
                    {interview?.length > 0 ? (
                      <>
                        {interview
                          ?.reverse()
                          ?.slice(0, 5)
                          ?.map((data, i) => (
                            <div
                              onClick={() => {
                                navigate("/interviewee/practice-interview");
                              }}
                              key={i}
                              className="reminder-msg-box c-pointer"
                            >
                              <div className="reminder-icon">
                                <BsFillSaveFill size={22} color="#521986" />
                              </div>
                              <div
                                style={{ width: "70%" }}
                                className="remainder-content"
                              >
                                <div>
                                  <h6>{data?.jobtitle}</h6>
                                  <p style={{ color: "#521986" }}>
                                    (Mock Interview)
                                  </p>
                                </div>
                                <h6 style={{ color: "#ffa303" }}>
                                  New Interview
                                </h6>
                              </div>
                              {/* <div className="remainder-time">6:30AM</div> */}
                            </div>
                          ))}
                      </>
                    ) : (
                      <>
                        <h6 className="mx-2 px-2">No Reminders</h6>
                      </>
                    )}
                    {/* <div className="reminder-msg-box">
                      <div className="reminder-icon">
                        <BsFillSaveFill size={22} color="#521986" />
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="remainder-content"
                      >
                        <div>
                          <h6>5 Min Interview</h6>
                          <p style={{ color: "#521986" }}>(Course)</p>
                        </div>
                        <h6 style={{ color: "#ffa303" }}>New course</h6>
                      </div>
                      <div className="remainder-time">6:30AM</div>
                    </div>

                    <div className="reminder-msg-box">
                      <div className="reminder-icon">
                        <BsFillSaveFill size={22} color="#521986" />
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="remainder-content"
                      >
                        <div>
                          <h6>UI Designer</h6>
                          <p style={{ color: "#521986" }}>(Interview)</p>
                        </div>
                        <h6 style={{ color: "#ffa303" }}>New Interview</h6>
                      </div>
                      <div className="remainder-time">6:30AM</div>
                    </div>
                    <div className="reminder-msg-box">
                      <div className="reminder-icon">
                        <BsFillSaveFill size={22} color="#521986" />
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="remainder-content"
                      >
                        <div>
                          <h6>UX Designer </h6>
                          <p style={{ color: "#521986" }}>(Interview)</p>
                        </div>
                        <h6 style={{ color: "#ffa303" }}>New Interview</h6>
                      </div>
                      <div className="remainder-time">6:30AM</div>
                    </div>
                    <div className="reminder-msg-box">
                      <div className="reminder-icon">
                        <BsFillSaveFill size={22} color="#521986" />
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="remainder-content"
                      >
                        <div>
                          <h6>Complete the Interview</h6>
                          <p style={{ color: "#521986" }}>(Daily Goal)</p>
                        </div>
                        <h6 style={{ color: "#ffa303" }}>Complete Goal</h6>
                      </div>
                      <div className="remainder-time">6:30AM</div>
                    </div>
                    <div className="reminder-msg-box">
                      <div className="reminder-icon">
                        <BsFillSaveFill size={22} color="#521986" />
                      </div>
                      <div
                        style={{ width: "50%" }}
                        className="remainder-content"
                      >
                        <div>
                          <h6>Recent Interview Report</h6>
                          <p style={{ color: "#521986" }}>(Pdf)</p>
                        </div>
                        <h6 style={{ color: "#ffa303" }}>Download</h6>
                      </div>
                      <div className="remainder-time">6:30AM</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-sec-2 mb-3">
              <div className="dashboard-sec-2-1 w-full">
                <div className="recommended-video d-none-525">
                  <div className="d-flex justify-content-between">
                    <YellowHeading className="p-3 pb-0">
                      Recommended Videos
                    </YellowHeading>
                    <div className="p-3">
                      <img
                        src={Lefticon}
                        alt="letf"
                        className="arrow-cover me-2"
                        id="swiper-back"
                      />
                      <img
                        src={Righticon}
                        alt="right"
                        className="arrow-cover"
                        id="swiper-forward"
                      />
                    </div>
                  </div>
                  <div className="card-slider p-3 pt-0 pe-0">
                    <Swiper
                      slidesPerView={"auto"}
                      spaceBetween={20}
                      // autoplay={{
                      //   delay: 3000,
                      //   disableOnInteraction: false,
                      // }}

                      navigation={{
                        nextEl: "#swiper-forward",
                        prevEl: "#swiper-back",
                      }}
                      modules={[Autoplay, Navigation]}
                      className="mySwiper pb-3 pe-3 pt-0"
                    >
                      {CardData.map((data) => (
                        <SwiperSlide>
                          <DashboardVcard data={data} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
                <div className="d-flex-525 flex-column gap-2">
                  {CardData.map((data) => (
                    <DashboardVcard data={data} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

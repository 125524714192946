import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState } from "react";
import "./sidebar.css";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { AiFillHome } from "react-icons/ai";

import { SupportFeedback } from "components/common/Support/SupportFeedback";
import { HelpDocumentation } from "components/common/Support/HelpDocumentation";
import { CreateInterview } from "container/Interviewer/CreateInterview/CreateInterview";
import CreateInterviewForm from "container/College/CreateInterview/CreateInterviewForm";

import { Dashboard } from "container/College/Dashboard/Dashboard";

import { MyInterviewRecorded } from "container/Interviewer/MyInterview/MyInterviewRecorded";

import { MyInterview } from "container/College/Myinterview/MyInterview";
import { SingleMyInterview } from "container/College/Myinterview/SingleMyInterview";
import { Profile } from "container/College/Profile/Profile";
import { Candidate } from "container/College/Candidate/Candidate";
import { TeamDetails } from "container/College/Candidate/TeamDetails";
import { AddBulkStudents } from "container/College/Candidate/AddBulkStudents";
import { NavbarCollege } from "components/Navbar/NavbarCollege";
import { DraftInterview } from "container/College/CreateInterview/DraftInterview";
import DraftInterviewForm from "container/College/CreateInterview/DraftInterviewForm";
import Notification from "container/College/Notifications/Notification";
import Reports from "container/Interviewee/Reports/Reports";
import { InterviewVideo } from "container/College/Myinterview/InterviewVideo";
import Cookies from "js-cookie";

export const SideBarAndHeaderCollge = ({ data }) => {
  const [hideSidebar, setHideSidebar] = useState(false);
  const navigate = useNavigate();
  const [updateprofile, setupdateprofile] = useState(true);

  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      navigate("/");
    }
  };
  return (
    <>
      <div className="d-flex">
        <div className={`${hideSidebar ? "col-w-6" : "col-w-20"} d-none-968`}>
          <SidePanel hideSidebar={hideSidebar}>
            <div className="px-3 py-4">
              <div className="min-height-sidebar">
                {data.slice(0, 6).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${
                        hideSidebar ? "wrapper__sidebar_hide" : ""
                      } d-flex align-items-center py-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <hr />
                {data.slice(6, 8).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${
                        hideSidebar ? "wrapper__sidebar_hide" : ""
                      } d-flex align-items-center py-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <a className="sidebar__link" href="https://viosa.in/">
                  {!hideSidebar ? (
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar
                  cursor-pointer"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2">Log-Out</h6>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center py-2 wrapper__sidebar wrapper__sidebar_hide cursor-pointer">
                      <AiFillHome />
                    </div>
                  )}
                </a>
              </div>
              <div
                className="sidebar__link"
                onClick={() => {
                  setHideSidebar(!hideSidebar);
                }}
              >
                {!hideSidebar ? (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar cursor-pointer">
                    <HiOutlineArrowNarrowLeft />
                    <h6 className="sidebar__heading px-2 mt-2">Hide Sidebar</h6>
                  </div>
                ) : (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar cursor-pointer wrapper__sidebar_hide">
                    <HiOutlineArrowNarrowRight />
                  </div>
                )}
              </div>
            </div>
          </SidePanel>
        </div>
        <div className={`${hideSidebar ? "col-w-94" : "col-w-80"} w-100-968`}>
          <div className="w-100">
            <NavbarCollege
              updateprofile={updateprofile}
              setupdateprofile={setupdateprofile}
            />
            <div className="main-content ">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-interview" element={<CreateInterview />} />
                <Route
                  type="college"
                  path="/create-interview/new"
                  element={<CreateInterviewForm />}
                />
                <Route
                  path="/create-interview/draft"
                  element={<DraftInterview />}
                />

                <Route
                  path="/create-interview/draft/:id"
                  element={<DraftInterviewForm />}
                />
                <Route
                  path="/edit-interview/:id"
                  element={<DraftInterviewForm />}
                />
                <Route path="/my-interviews" element={<MyInterview />} />
                <Route
                  path="/my-interviews/:id"
                  element={<SingleMyInterview />}
                />
                <Route path="/reports/:id" element={<Reports />} />
                <Route
                  path="/interview-video/:id"
                  element={<InterviewVideo />}
                />
                <Route
                  path="/my-interviews/:interViewId/recorded"
                  element={<MyInterviewRecorded />}
                />
                <Route path="/batches" element={<Candidate type="college" />} />
                <Route
                  path="/batches/bulk-students"
                  element={<AddBulkStudents type="college" />}
                />
                <Route
                  path="/batches/:id"
                  element={<TeamDetails type="college" />}
                />
                <Route
                  path="/profile"
                  element={
                    <Profile
                      updateprofile={updateprofile}
                      setupdateprofile={setupdateprofile}
                    />
                  }
                />
                <Route path="/help" element={<HelpDocumentation />} />
                <Route path="/support-feedback" element={<SupportFeedback />} />
                <Route path="/notifications" element={<Notification />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import axios from "axios";
import Cookies from "js-cookie";
const API = axios.create({
  baseURL: "https://interview-api.viosa.in",
  // baseURL: "http://localhost:4800",
});

API.interceptors.request.use((req) => {
  if (Cookies.get("token")) {
    req.headers["token"] = Cookies.get("token");
  }
  return req;
});

export const LoginAll = (formData) => API.post("/auth/login", formData);
export const FeedbackSubmit = (formData) =>
  API.post("/feedback/storefeedback", formData);

// forget password
export const ForgetPasswordOtp = (formData) =>
  API.post("/auth/forgetPassword", formData);
export const VerifyOtpPassword = (formData) =>
  API.post("/auth/verifyCode", formData);
export const ResetPasswordForget = (formData) =>
  API.post("/auth/resetPassword", formData);

// student

export const StudentLogin = (formData) =>
  API.post("/auth/student/login", formData);
export const StudentSignUp = (formData) =>
  API.post("/auth/student/signup", formData);
export const UpdateStudentProfile = (formData) =>
  API.post("/auth/student/profile", formData);
export const GetCollegeStudents = () => API.get("/auth/college/getallstudent");
export const GetStudentsDetails = () => API.get("/auth/student/details");
export const StudentPasswordUpdate = (formData) =>
  API.post("/auth/student/updatepassword", formData);
export const getStudentDashboardReq = () => API.get("/auth/student/dashboard");
export const getStudentNotificationReq = () =>
  API.get("/auth/student/notifications");

// interviewee

export const IntervieweeSignUp = (formData) =>
  API.post("/auth/interviewee/signup", formData);
export const IntervieweeLogin = (formData) => API.post("/auth/login", formData);
export const GetIntervieweeDetails = () => API.get("/auth/interviewee/details");
export const UpdateIntervieweeProfile = (formData) =>
  API.post("/auth/interviewee/profile", formData);
export const IntervieweePasswordUpdate = (formData) =>
  API.post("/auth/interviewee/update-password", formData);
export const ApplyInterview = (formData) =>
  API.post("/interview/apply-interview", formData);
export const getDashboardReq = () => API.get("/auth/interviewee/dashboard");
export const getIntervieweeNotificationReq = () =>
  API.get("/auth/interviewee/notifications");

// college

export const CollegeLogin = (formData) =>
  API.post("/auth/college/login", formData);
export const CollegeSignUp = (formData) =>
  API.post("/auth/college/signup", formData);
export const GetCollege = () => API.get("/auth/college");
export const CollegePasswordUpdate = (formData) =>
  API.post("/auth/college/updatepassword", formData);
export const CreateNewTeam = (formData) =>
  API.post("/auth/college/createteam", formData);
export const BulkStudents = (formData) =>
  API.post("/auth/college/createmultiplestudents", formData);
export const UpdateCollege = (formData) =>
  API.post("/auth/college/updateprofile", formData);
export const UpdateCollegeTeam = (formData) =>
  API.post("/auth/college/updateteam", formData);
export const AssignInterview = (formData) =>
  API.post("/interview/assign-interview", formData);
export const AssignInterviewtoTeam = (formData) =>
  API.post("/interview/assign-multiple-interview", formData);
export const AddStudentCreateTeam = (formData) =>
  API.post("/auth/college/addmultiplestudentstoteam", formData);
export const GetCollegeTeams = () => API.get("/auth/college/teams");
export const GetCollegeTeamById = ({ id }) =>
  API.get(`/auth/college/team/${id}`);
export const DeleteTeam = ({ id }) => API.delete(`/auth/college/team/${id}`);
export const DeleteMultipleStudents = (formdata) =>
  API.post("/auth/college/deleteStudent", formdata);
export const RemoveTeamStudent = (formdata) =>
  API.post("/auth/college/removeteammembers", formdata);
export const getCollegeDashboardReq = () => API.get("/auth/college/dashboard");
export const getCollegeNotificationReq = () =>
  API.get("/auth/college/notifications");

// corporate

export const CorporateLogin = (formData) =>
  API.post("/auth/corporate/login", formData);
export const CorporateSignUp = (formData) =>
  API.post("/auth/corporate/signup", formData);
export const GetCorporate = () => API.get("/auth/corporate");
export const CorporatePasswordUpdate = (formData) =>
  API.post("/auth/corporate/changepassword", formData);
export const UpdateCorporate = (formData) =>
  API.post("/auth/corporate/updateprofile", formData);
export const getCorporateDashboardReq = () =>
  API.get("/auth/corporate/dashboard");
export const getCorporateNotificationReq = () =>
  API.get("/auth/corporate/notifications");
export const ShortListForInterview = (formData) =>
  API.post("interview/shortlistCandidate", formData);
export const AppliedStudents = (formData) =>
  API.post("auth/corporate/allCandidates", formData);

// SuperAdmin

export const SuperAdminLogin = (formData) =>
  API.post("/auth/admin/login", formData);
export const SuperAdminSignUp = (formData) =>
  API.post("/auth/admin/signup", formData);
export const GetCorporatesAll = () => API.get("/auth/corporate/all");
export const UpdateCorporateById = (data) =>
  API.post(`/auth/admin/update/corporate/${data.id}`, data);
export const GetCollegesAll = () => API.get("/auth/college/all");
export const GetIntervieweeAll = () => API.get("/auth/admin/allinterviewee");
export const UpdateCollegeById = (data) =>
  API.post(`/auth/admin/update/college/${data.id}`, data);
export const DeleteCollegeById = (data) =>
  API.delete(`/auth/admin/delete/college/${data.id}`);
export const DeleteCorporateById = (data) =>
  API.delete(`/auth/admin/delete/college/${data.id}`);
export const GetAdmin = () => API.get("/auth/admin/");
export const AdminPasswordUpdate = (formData) =>
  API.post("/auth/admin/updatepassword", formData);
export const UpdateAdmin = (formData) =>
  API.post("/auth/admin/updateprofile", formData);
export const getAdminDashboardReq = () => API.get("/auth/admin/dashboard");
export const getCandidateStats = () => API.get("/auth/admin/statsCandidate");
export const getInterviewerStats = () =>
  API.get("/auth/admin/statsInterviewer");
// export const DeleteUserApi = (userId) =>
//   API.delete(`/auth/delete/user/${userId}`); // Adjust the endpoint based on your server route

// Interview

export const GetAllInterviewCorporate = (data) =>
  API.get(
    `/interview/all/corporate?page=${data.page}&limit=${data.limit}&title=${data?.title}`
  );
export const GetAllInterviewAdmin = (data) =>
  API.get(
    `/interview/all/admin?page=${data.page}&limit=${data.limit}&title=${data?.title}`
  );
export const GetAllCreatorInterview = () => API.get(`/interview/creator`);
export const GetAllCreatorInterviewByLimit = (data) =>
  API.get(
    `/interview/creator?page=${data.page}&limit=${data.limit}&title=${data.title}`
  );
export const GetSingleInterview = (id) =>
  API.post("/interview/single-interview", id);
export const UpdateInterview = (data) =>
  API.patch("/interview/update-interview", data);
export const SubmitInterview = (data) =>
  API.post(`/interview/submit/${data?.id}`, data);
// export const InterviewAnalysis = (data) =>
//   API.post(`/interview/analysis`, data);
export const InterviewAnalysis = (data) =>
  API.post(`/interview/submitInterview`, data);
export const InterviewReport = (data) =>
  API.get(`/interview/submitted/${data.id}`);
export const DeleteInterviewById = ({ id }) =>
  API.delete(`/interview/delete/${id}`);
export const SearchInterview = (data) =>
  API.post(`/interview/search-interviews`, data);

// Create update Interview

export const CreateInterview = (formData) =>
  API.post("/interview/createinterview", formData);
export const CreateSkills = (formData) =>
  API.post("/interview/createskills", formData);
export const GetallSkillsCategory = () => API.get("/interview/skills/");
export const CreateQuestions = (formData) =>
  API.post("/interview/createquestion", formData);
export const UpdateQuestions = (formData) =>
  API.post("/interview/addNewQuestion", formData);
export const GetallQuestionsCategory = () => API.get("/interview/questions");

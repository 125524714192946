import { GridSearchIcon } from "@mui/x-data-grid";
import { Card } from "components/Card/CardStyles";
import {
  BoxStyle,
  ButtonBlue,
  ButtonYellowOutline,
  Circle,
  DashboardBoxStyle,
  SearchInput,
  Wrapper,
  YellowHeading,
} from "components/common/CommonStyles";
import React from "react";
import { CreateInterviewMockData } from "./contants/data";
import { Link, useNavigate } from "react-router-dom";
import plus from "assets/image/plus.png";
import drive from "assets/image/drive.png";
import plusblue from "assets/plus.png";
import driveblue from "assets/drive.png";
import podcast from "assets/podcast.png";
import message from "assets/message.png";

export const CreateInterview = ({ type }) => {
  const navigate = useNavigate();
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container my-3">
        <Wrapper>
          <SearchInput placeholder="Search" className="border-10" />
          <GridSearchIcon
            style={{ position: "absolute", right: "20px", top: "20px" }}
          />
        </Wrapper>
        <div>
          <div className="py-3 my-2 ">
            <div className="col-12">
              <div className="row gx-2">
                {type !== "superadmin" && (
                  <>
                    <div className="col-6 w-100-525 col-md-3 col-sm-4  mb-3 ">
                      <Link to="new">
                        <Card
                          onClick={() => navigate("new")}
                          className="d-flex justify-content-center align-items-center  flex-column cursor-pointer"
                          style={{ height: "400px" }}
                        >
                          <Circle>
                            <img src={plus} className="plus-icon" height={20} />
                          </Circle>
                          <div>
                            <YellowHeading className="fs-20 my-2">
                              Create Interview
                            </YellowHeading>
                          </div>
                        </Card>
                      </Link>
                    </div>
                    <div className="col-6 w-100-525 col-md-3 col-sm-4 mb-3 ">
                      <Link to="draft">
                        <Card
                          className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
                          style={{ height: "400px" }}
                        >
                          <Circle>
                            <img
                              src={drive}
                              className="plus-icon"
                              height={20}
                            />
                          </Circle>
                          <div>
                            <YellowHeading className="fs-20 my-2">
                              Draft Interviews
                            </YellowHeading>
                          </div>
                        </Card>
                      </Link>
                    </div>
                  </>
                )}
                {type === "superadmin" && (
                  <>
                    <div className="col-6 w-100-525 col-md-3 col-sm-4 mb-3 ">
                      <Card
                        onClick={() => navigate("new")}
                        className="d-flex justify-content-center align-items-center flex-column cursor-pointer px-1"
                        style={{ height: "400px" }}
                      >
                        <Circle color="secondary">
                          <img
                            src={message}
                            className="plus-icon"
                            height={20}
                          />
                        </Circle>
                        <div>
                          <YellowHeading className="fs-20 my-2">
                            Create Mock Interview
                          </YellowHeading>
                        </div>
                      </Card>
                    </div>
                    <div className="col-6 w-100-525 col-md-3 col-sm-4 mb-3 ">
                      <Card
                        onClick={() => navigate("new")}
                        className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
                        style={{ height: "400px" }}
                      >
                        <Circle color="secondary">
                          <img src={plus} className="plus-icon" height={20} />
                        </Circle>
                        <div>
                          <YellowHeading className="fs-20 my-2">
                            Create Job Interview
                          </YellowHeading>
                        </div>
                      </Card>
                    </div>
                    <div className="col-6 w-100-525 col-md-3 col-sm-4 mb-3 ">
                      <Card
                        className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
                        style={{ height: "400px" }}
                      >
                        <Circle color="secondary">
                          <img src={drive} className="plus-icon" height={20} />
                        </Circle>
                        <div>
                          <YellowHeading className="fs-20 my-2">
                            Draft Interviews
                          </YellowHeading>
                        </div>
                      </Card>
                    </div>
                    <div className="col-6 w-100-525 col-md-3 col-sm-4 mb-3 ">
                      <Card
                        onClick={() =>
                          navigate("/superadmin/interview/publish")
                        }
                        className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
                        style={{ height: "400px" }}
                      >
                        <Circle color="secondary">
                          <img
                            src={podcast}
                            className="plus-icon"
                            height={20}
                          />
                        </Circle>
                        <div>
                          <YellowHeading className="fs-20 my-2">
                            Published Interviews
                          </YellowHeading>
                        </div>
                      </Card>
                    </div>
                  </>
                )}
                {type === "superadmin" && (
                  <YellowHeading className="my-2">Templates</YellowHeading>
                )}
                {/* {CreateInterviewMockData.map((item) => (
                  <div className="col-3 mb-3" key={item.id}>
                    <Link to={`${item.id}`}>
                      <Card style={{ height: "400px" }}>
                        <div className="card-image-wrapper create-interview-image">
                          <img src={item.image} />
                        </div>
                        <div className="card-content-wrapper my-3">
                          <h4 className="card-title-2">{item.name}</h4>
                          <p className="card-description-2 pt-1">
                            {item.description}
                          </p>
                        </div>
                        <div className="card-footer-wrapper mt-2">
                          <div className="card-footer d-flex justify-content-between">
                            <div className="card-footer-left w-100">
                              <ButtonBlue className="primary h-40" width="97%">
                                Use this template
                              </ButtonBlue>
                            </div>
                            <div className="card-footer-right">
                              <ButtonYellowOutline className="h-40">
                                <img
                                  src={item.icon}
                                  style={{ width: "14px", height: "14px" }}
                                />
                              </ButtonYellowOutline>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardBoxStyle>
  );
};

import { SidePanel } from "components/Sidepanel/SidePanel";
import React, { useState } from "react";
import "./sidebar.css";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { AiFillHome } from "react-icons/ai";
import { NavbarInterviewer } from "components/Navbar/NavbarInterviewer";
import { SupportFeedback } from "components/common/Support/SupportFeedback";
import { HelpDocumentation } from "components/common/Support/HelpDocumentation";
import { CreateInterview } from "container/Interviewer/CreateInterview/CreateInterview";
import CreateInterviewForm from "container/Interviewer/CreateInterview/CreateInterviewForm";
import { MyInterview } from "container/Interviewer/MyInterview/MyInterview";
import { Profile } from "container/Interviewer/Profile/Profile";
import { Candidate } from "container/Interviewer/Candidates/Candidate";
import { Dashboard } from "container/Interviewer/Dashboard/Dashboard";
import { SingleMyInterview } from "container/Interviewer/MyInterview/SingleMyInterview";
import { MyInterviewRecorded } from "container/Interviewer/MyInterview/MyInterviewRecorded";
import { DraftInterviews } from "container/Interviewer/CreateInterview/DraftInterviews";
import DraftInterviewForm from "container/Interviewer/CreateInterview/DraftInterviewForm";
import Reports from "container/Interviewee/Reports/Reports";
import Notification from "container/Interviewer/Notifications/Notification";
import { InterviewVideo } from "container/Interviewer/MyInterview/InterviewVideo";
import Cookies from "js-cookie";

export const SideBarHeaderInterviewer = ({ data }) => {
  const [hideSidebar, setHideSidebar] = useState(false);
  const [updateprofile, setupdateprofile] = useState(true)

  const navigate = useNavigate();
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      navigate("/");
    }
  };
  return (
    <>
      <div className="d-flex">
        <div className={`${hideSidebar ? "col-w-6" : "col-w-20"} d-none-968`}>
          <SidePanel hideSidebar={hideSidebar}>
            <div className="px-3 py-4">
              <div className="min-height-sidebar">
                {data.slice(0, 6).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link "
                    key={item.id}
                  >
                    <div
                      className={`${hideSidebar ? "wrapper__sidebar_hide" : ""
                        } d-flex align-items-center py-2 wrapper__sidebar bg-purple`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading color-white px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <hr />
                {data.slice(6, 8).map((item) => (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${hideSidebar ? "wrapper__sidebar_hide" : ""
                        } d-flex align-items-center py-2 wrapper__sidebar bg-purple`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading color-white px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                ))}
                <a className="sidebar__link" href="https://viosa.in/">
                  {!hideSidebar ? (
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar
                  cursor-pointer bg-purple"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading color-white px-2 mt-2">Go To Dashboard</h6>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center py-2 wrapper__sidebar bg-purple wrapper__sidebar_hide cursor-pointer">
                      <AiFillHome />
                    </div>
                  )}
                </a>
              </div>
              <div
                className="sidebar__link"
                onClick={() => {
                  setHideSidebar(!hideSidebar);
                }}
              >
                {!hideSidebar ? (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar bg-purple cursor-pointer">
                    <HiOutlineArrowNarrowLeft />
                    <h6 className="sidebar__heading color-white px-2 mt-2">Hide Sidebar</h6>
                  </div>
                ) : (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar bg-purple wrapper__sidebar_hide cursor-pointer">
                    <HiOutlineArrowNarrowRight />
                  </div>
                )}
              </div>
            </div>
          </SidePanel>
        </div>
        <div className={`${hideSidebar ? "col-w-94" : "col-w-80"} w-100-968`}>
          <div className="w-100">
            <NavbarInterviewer updateprofile={updateprofile} setupdateprofile={setupdateprofile} />
            <div className="main-content ">
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-interview" element={<CreateInterview />} />
                <Route
                  path="/create-interview/new"
                  element={<CreateInterviewForm />}
                />
                <Route
                  path="/create-interview/draft"
                  element={<DraftInterviews />}
                />
                <Route
                  path="/create-interview/draft/:id"
                  element={<DraftInterviewForm />}
                />
                <Route
                  path="/edit-interview/:id"
                  element={<DraftInterviewForm />}
                />
                <Route path="/my-interviews" element={<MyInterview />} />
                <Route path="/interview-video/:id/:id2" element={<InterviewVideo />} />
                <Route path="/reports/:id" element={<Reports />} />
                <Route
                  path="/my-interviews/:id"
                  element={<SingleMyInterview />}
                />
                <Route
                  path="/interview/:candidateId"
                  element={<MyInterviewRecorded />}
                />
                <Route path="/candidates" element={<Candidate />} />
                <Route path="/profile" element={<Profile updateprofile={updateprofile} setupdateprofile={setupdateprofile} />} />
                <Route path="/help" element={<HelpDocumentation />} />
                <Route path="/support-feedback" element={<SupportFeedback />} />
                <Route path="/notifications" element={<Notification />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

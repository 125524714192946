import {
  ButtonBlue,
  ButtonPurpleOutline,
  ButtonWhite,
  Input,
  Select,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineMail,
  AiOutlineMobile,
} from "react-icons/ai";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { FaLessThanEqual, FaUserFriends, FaUsers } from "react-icons/fa";
import {
  CollegeSignUp,
  CorporateSignUp,
  IntervieweeSignUp,
  StudentSignUp,
  SuperAdminSignUp,
} from "../../axios/apis";
import { useNavigate } from "react-router";
import { ToastStyles } from "components/common/ToastStyle";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

export const Signup = ({ setSelect }) => {
  const [showpassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [signupPage, setSignupPage] = useState(1);
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");
  const [loading, setLoading] = useState(false);
  const [signuploading, setsignupLoading] = useState(false);
  const [resendotp, setResendotp] = useState(false);
  const [resendActive, setResendActive] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [appVerifier, setAppverifier] = useState();
  const [otploading, setotploading] = useState(false);
  const [data, setdata] = useState({
    phoneNumber: "",
    role: "",
    verificationCode: "",
    firstname: "",
    lastname: "",
    email: "",
    confirmemail: "",
    password: "",
    confirmpassword: "",
    company: "",
    dob: "",
    age: "",
    interviewExp: "",
    testskill: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    setdata({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const getOtp = () => {
    // console.log(data.phoneNumber.length);
    if (data.phoneNumber.length < 12) {
      alert("Please Enter a valid Number", ToastStyles);
    } else {
      // setResendotp(false);
      getOtpFun();
    }
  };
  const getOtpFun = () => {
    setotploading(true)
    let appverifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+" + data.phoneNumber, appverifier)
      .then((result) => {
        // console.log(result);
        setfinal(result);
        alert("Otp sent Successfully")
        setAppverifier(appverifier);
        // toast.success("Verification Code sent", ToastStyles);
        setResendotp(true);
        setshow(true);
        setResendActive(true);
        setotploading(false)
        setMinutes(1);
        setSeconds(30);
      })
      .catch((err) => {
        // console.log(err);
        alert(err, ToastStyles);
        setotploading(false)
        // window.location.reload();
      });
  };
  const resendOtp = () => {
    if (data.phoneNumber.length < 12) {
      alert("Please Enter a valid Number", ToastStyles);
    } else {
      setotploading(true)
      signInWithPhoneNumber(auth, "+" + data.phoneNumber, appVerifier)
        .then((result) => {
          setfinal(result);
          alert("Otp sent Successfully")

          // toast.success("Verification Code sent", ToastStyles);
          setResendotp(true);
          setshow(true);
          setResendActive(true);
          setotploading(false)
          setMinutes(1);
          setSeconds(30);
        })
        .catch((err) => {
          // console.log(err);
          setotploading(false)
          // window.location.reload();
        });
    }
  };
  const ValidateOtp = () => {
    // console.log(data.verificationCode);
    if (data.verificationCode === null || final === null) return;
    if (data.role === "corporate" && data.company === "") {
      alert("Please Enter Company Name", ToastStyles);
      return;
    }
    if (data.role === "") {
      alert("Please Select Role", ToastStyles);
      return;
    } else {
      setLoading(true);
      final
        .confirm(data.verificationCode)
        .then((result) => {
          // console.log(result);
          setSignupPage(2);
          setLoading(FaLessThanEqual);
        })
        .catch((err) => {
          alert("Incorrect Otp", ToastStyles);
          setLoading(false);
        });
    }
  };
  const handleSignup = async (e) => {
    e.preventDefault();
    if (data.password !== data.confirmpassword) {
      alert("Password doesn't Match");
      return;
    }
    setsignupLoading(true);
    const { phoneNumber, firstname, lastname, email, password, role, company } =
      data;

    if (role === "student") {
      const formdata = {
        phoneNumber,
        password,
        email,
        firstname,
        lastname,
        role: "interviewee"
      };
      try {
        // debugger;
        const res = await IntervieweeSignUp(formdata);
        // console.log(res)
        if (res?.data?.status === 400) {
          alert("Email already Exist");
        }
        else if (res?.data?.status === 500) {
          alert("Account already Exist");
        }
        else {
          // console.log(res);
          setSignupPage(false);
          Cookies.set("token", res?.data?.token);
          Cookies.set("role", res?.data?.type);
          Cookies.set("name", res?.data?.data?.interviewee?.firstname);
          Cookies.set("email", res?.data?.data?.interviewee?.email);
          Cookies.set("profileImg", res?.data?.data?.interviewee?.profile);
          navigate("/interviewee/dashboard");
        }
      } catch (error) {
        // console.log(error);
        alert(error.response.data.error)

        setsignupLoading(false);
      }
    } else if (role === "corporate") {
      const formdata = {
        phoneNumber,
        password,
        email,
        firstname,
        lastname,
        company,
        role,
      };
      try {
        // debugger;
        const res = await CorporateSignUp(formdata);
        if (res?.data?.status === 400) {
          alert("Email already Exist", ToastStyles);
        }
        else if (res?.data?.status === 500) {
          alert("Account already Exist");
        }
        else {
          // console.log(res);
          setSignupPage(false);
          alert("Sign up successfully, Wait for Approval and login")
          // Cookies.set("token", res?.data?.token);
          // Cookies.set("userDetails", res?.data?.data?.corporate);
          // navigate("/interviewer/dashboard");
        }
      } catch (error) {
        // console.log(error);
        alert(error.response.data.message)
        setsignupLoading(false);
      }
    } else if (role === "college") {
      const formdata = {
        phoneNumber,
        password,
        email,
        role,
        collegename: firstname,
      };
      try {
        // debugger;
        const res = await CollegeSignUp(formdata);
        if (res?.data?.status === 400) {
          alert("Email already Exist");
        }
        else if (res?.data?.status === 500) {
          alert("Account already Exist");

        } else {
          // console.log(res);
          setSignupPage(false);
          alert("Sign up successfully, Wait for Approval and login")
          // Cookies.set("token", res?.data?.token);
          // Cookies.set("collegeID", res?.data?.data?.college?._id);
          // navigate("/college/dashboard");
        }
      } catch (error) {
        // console.log(error);
        alert(error.response.data.message)
        setsignupLoading(false);
      }
    }
    setsignupLoading(false);
  };
  // console.log(data.phoneNumber);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setResendActive(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        // callback: getOtpFun,
      },
      auth
    );
  }, []);

  return (
    <div className="mt-4">
      <div id="recaptcha-container"></div>
      {signupPage == 1 ? (
        <>
          {" "}
          <div className="d-flex align-items-center">
            <FaUserFriends className="input-icon" color="#ffa303" size={28} />
            <Select
              className="mb-3 px-5 text-purple br-5"
              name="role"
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select Role</option>
              <option value="student">Student/Interviewee</option>
              {/* <option value="superadmin">Super Admin</option> */}
              <option value="college">College/Institute</option>
              <option value="corporate">Corporate</option>
            </Select>
          </div>
          {data.role === "corporate" && (
            <div className="d-flex align-items-center">
              <FaUsers className="input-icon" color="#ffa303" size={28} />
              <Input
                type="text"
                name="company"
                value={data.company}
                onChange={(e) => handleChange(e)}
                placeholder="Company Name"
                className="mb-3 px-5 br-5"
              ></Input>
            </div>
          )}
          {
            (data?.role === "college" || data?.role === "corporate") ? (<><ButtonBlue
              onClick={()=>setSignupPage(2)}
              className="w-100 h-40 box-shadow"
            >
              Next
            </ButtonBlue></>) : (<><div className="">
              <ReactPhoneInput
                country="in"
                style={{
                  width: "100%",
                  borderRadius: "12px",
                }}
                enableSearch
                name="phoneNumber"
                value={data.phoneNumber || ""}
                onChange={(e) => {
                  setdata({ ...data, phoneNumber: e });
                  setResendotp(false);
                  setMinutes(0);
                  setSeconds(0);
                }}
                className="mb-3 br-5"
              />
            </div>
              <div className="d-flex justify-content-between mb-0">
                <div className="d-flex align-items-center me-1">
                  <AiOutlineMail className="input-icon" color="#ffa303" size={28} />
                  <Input
                    type="tel"
                    name="verificationCode"
                    className="mb-3 px-5 br-5"
                    value={data.verificationCode}
                    onChange={(e) => handleChange(e)}
                    placeholder="Verification code"
                  ></Input>
                </div>

                {resendotp ? (
                  <>
                    {otploading ? (<ButtonWhite
                      className=" br-5"
                    >
                      <Spinner animation="border" size="sm" />
                    </ButtonWhite>) : (<ButtonWhite
                      disabled={resendActive}
                      onClick={resendOtp}
                      className=" br-5"
                    >
                      Resend
                    </ButtonWhite>)}
                  </>

                ) : (
                  <>
                    {otploading ? (<ButtonWhite className=" br-5">
                      <Spinner animation="border" size="sm" />
                    </ButtonWhite>) : (<ButtonWhite onClick={getOtp} className=" br-5">
                      Send Code
                    </ButtonWhite>)}

                  </>
                )}
              </div>
              {(minutes > 0 || seconds > 0) && (
                <>
                  <p className="time-remaining">
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                </>
              )}
              {show && (
                <div className="mt-3">
                  {loading ? (
                    <>
                      <ButtonBlue disabled className="w-100 h-40 box-shadow">
                        <Spinner animation="border" size="sm" />
                      </ButtonBlue>
                    </>
                  ) : (
                    <>
                      <ButtonBlue
                        onClick={ValidateOtp}
                        className="w-100 h-40 box-shadow"
                      >
                        Next
                      </ButtonBlue>
                    </>
                  )}
                </div>
              )}</>)
          }

        </>
      ) : signupPage === 2 ? (
        <>
          <Form onSubmit={handleSignup}>
            {data.role === "college" ? (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-title">Name</Form.Label>
                <Form.Control
                  className="form-input"
                  type="text"
                  name="firstname"
                  required
                  value={data.firstname}
                  onChange={handleChange}
                  placeholder="College Name"
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="form-title">Name</Form.Label>
                <div className="d-flex justify-content-between">
                  <Form.Control
                    style={{ width: "48%" }}
                    className="form-input"
                    type="text"
                    name="firstname"
                    required
                    value={data.firstname}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  <Form.Control
                    className="form-input"
                    type="text"
                    style={{ width: "48%" }}
                    name="lastname"
                    value={data.lastname}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                </div>
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="form-title">Email</Form.Label>
              <Form.Control
                className="form-input"
                type="email"
                required
                name="email"
                value={data.email}
                onChange={handleChange}
                placeholder="Email"
              /></Form.Group>
            <Form.Group className="mb-0" controlId="formBasicEmail">
              <Form.Label className="form-title">Password</Form.Label>
              <Form.Control
                className="form-input"
                type={showpassword ? "text" : "password"}
                name="password"
                required
                minLength={8}
                value={data.password}
                onChange={handleChange}
                placeholder="Enter Password"
              />
              {showpassword ? (
                <div className="eye-position">

                  <AiFillEyeInvisible
                    onClick={() => setShowPassword(false)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  />
                </div>
              ) : (
                <div className="eye-position">
                  <AiFillEye
                    onClick={() => setShowPassword(true)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  /></div>
              )}
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Control
                className="form-input"
                type={showconfirmpassword ? "text" : "password"}
                required
                minLength={8}
                name="confirmpassword"
                value={data.confirmpassword}
                onChange={handleChange}
                placeholder="Re-enter Password"
              />
              {showconfirmpassword ? (
                <div className="eye-position">

                  <AiFillEyeInvisible
                    onClick={() => setShowConfirmPassword(false)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  />
                </div>
              ) : (
                <div className="eye-position">
                  <AiFillEye
                    onClick={() => setShowConfirmPassword(true)}
                    className="password-eye1 pe-1"
                    color="#ffa303"
                    size={28}
                  /></div>
              )}
            </Form.Group>
            {signuploading ? (
              <>
                <ButtonBlue disabled className="w-100 h-40 box-shadow">
                  <Spinner animation="border" size="sm" />
                </ButtonBlue>
              </>
            ) : (
              <>
                <ButtonBlue type="submit" className="w-100 h-40 box-shadow">
                  Sign Up
                </ButtonBlue>
              </>
            )}
          </Form>
        </>
      ) : signupPage === 3 ? (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Date of Birth</Form.Label>
            <div className="d-flex justify-content-between">
              <Form.Control
                style={{ width: "48%" }}
                className="form-input"
                type="date"
                name="dob"
                value={data.dob}
                onChange={handleChange}
                placeholder="DD-MM-YYYY"
              />
              <Form.Control
                className="form-input"
                type="number"
                style={{ width: "48%" }}
                name="age"
                value={data.age}
                onChange={handleChange}
                placeholder="Age Auto calculated"
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Interview Experience</Form.Label>

            <Form.Select
              id="disabledSelect"
              name="interviewExp"
              onChange={handleChange}
              className="form-input"
            >
              <option default value="">
                Experience
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-end my-3">
            <ButtonBlue
              className="w-10 h-40 box-shadow px-3"
              onClick={() => setSignupPage(4)}
            >
              Next
            </ButtonBlue>
          </div>
        </>
      ) : signupPage === 4 ? (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Test Your Skills</Form.Label>

            <Form.Select
              id="disabledSelect"
              name="testskill"
              onChange={handleChange}
              className="form-input"
            >
              <option default value="">
                Experience
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-center my-3">
            <ButtonBlue
              className="w-10 h-40 box-shadow px-5"
              onClick={() => setSignupPage(5)}
            >
              Test
            </ButtonBlue>
          </div>
        </>
      ) : signupPage === 5 ? (
        <>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="form-title">Test Your Skills</Form.Label>

            <Form.Select
              id="disabledSelect"
              name="testskill"
              onChange={handleChange}
              className="form-input"
            >
              <option default value="">
                Experience
              </option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </Form.Select>
          </Form.Group>
          <div className="d-flex justify-content-evenly my-3">
            <ButtonBlue className="w-10 h-40 box-shadow px-5">
              Free Test
            </ButtonBlue>
            <ButtonPurpleOutline
              className="w-10 h-40 box-shadow px-5"
              onClick={() => setSelect(0)}
            >
              Login
            </ButtonPurpleOutline>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

import {
  BoxStyle,
  ButtonBlue,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
// import "./interview.css";
import {
  GetAllCreatorInterview,
  GetAllCreatorInterviewByLimit,
} from "../../../axios/apis";
import { Table } from "components/Table/Table";
import Moment from "react-moment";
import { Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { MdFileCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Loading from "components/common/Loading";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";
import ReactPaginate from "react-paginate";

export const MyInterview = ({ type }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [interview, setInterView] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpages, setTotalpages] = useState(1);
  // const arr = [
  //   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  //   22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  // ];
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllCreatorInterviewByLimit({
        page: page,
        limit: 10,
        title: "",
      });
      // Filter interviews based on creator_type
      const corporateInterviews = response.data.interviews.filter(
        (interview) => interview.creator_type === "corporate"
      );
      setTotalpages(response.data.totalpages);
      // setInterView(response.data.interviews);
      setInterView(corporateInterviews);
      // console.log(response.data.interviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllInterviews();
  }, [page]);
  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
  };
  const columns = [
    {
      field: "jobtitle",
      headerName: "ROLE",
      minWidth: 200,
      renderCell: (data) => (
        <div
          className="d-flex
        align-items-center "
          onClick={() => {
            // console.log(data);
          }}
        >
          {data?.value}
        </div>
      ),
    },
    {
      field: "joblocation",
      headerName: "JOB LOCATION",
      minWidth: 200,
      // renderCell: (data) => (
      //   <div
      //     className="d-flex
      //   align-items-center ms-4"
      //     onClick={(e) => {
      //       console.log(e);
      //     }}
      //   >
      //     {data?.value}
      //   </div>
      // ),
    },
    {
      field: "createdAt",
      headerName: "CREATED ON",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "expirydate",
      headerName: "EXPIRY DATE",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {" "}
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    // {
    //   field: "student",
    //   headerName: "STUDENTS",
    //   minWidth: 150,
    //   renderCell: (data) => (
    //     <div className="d-flex align-items-center ">{data.row?.attemptedby?.length||0}</div>
    //   ),
    // },
    {
      field: "interviewResult",
      headerName: "RESPONSES",
      minWidth: 150,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {data.value.length || 0}
        </div>
      ),
    },
    {
      field: "draft",
      headerName: "STATUS",
      minWidth: 70,
      renderCell: (params) => (
        <div className="d-flex align-items-center justify-content-center">
          {params.value ? "🔴" : "🟢"}
        </div>
      ),
    },

    // {
    //   field: "shortlist",
    //   headerName: "SHORTLISTED",
    //   minWidth: 150,

    //   renderCell: (params) => (
    //     <div className="d-flex align-items-center ms-4">{params.value}</div>
    //   ),
    // },

    {
      field: "share",
      headerName: "SHARE",
      minWidth: 120,
      renderCell: (data) => (
        <Tooltip title="Copy to Clipboard">
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(
                window.location.origin +
                  "/interview/apply-interview/" +
                  data?.row?._id
              );
              toast.success("Link Copied to Clipboard", ToastStyles);
            }}
            className="d-flex align-items-center ms-1"
            style={{ color: " #521986" }}
          >
            <MdFileCopy fontSize={24} color="#521986" />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "act",
      headerName: "ACTION",
      minWidth: 70,
      renderCell: (data) => (
        <div
          onClick={() => navigate(`${data.row._id}`)}
          className="d-flex align-items-center text-hover"
        >
          View more
        </div>
      ),
    },
  ];
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <div className="my-2 border-10">
              <div className="d-flex justify-content-between">
                <YellowHeading className="mb-0" style={{ lineHeight: "50px" }}>
                  My Interviews
                </YellowHeading>
                <ButtonBlue
                  className="px-3"
                  onClick={() =>
                    navigate(
                      type === "superadmin"
                        ? "/superadmin/create-interview/new"
                        : "/hire-pro/create-interview/new"
                    )
                  }
                >
                  Create new interview
                </ButtonBlue>
              </div>
              {/* <MultiStepProgress /> */}
            </div>

            <Table
              columns={columns}
              data={interview.map((item) => {
                return {
                  ...item,
                  id: item._id,
                  key: item._id,
                };
              })}
              data2={interview}
              limit={10}
              checkbox={false}
            />
          </div>
          {/* <MDBPagination center className="my-1">
            {arr.slice(0, totalpages).map((data, i) => (
              <MDBPaginationItem active={page === i + 1}>
                <MDBPaginationLink onClick={() => setPage(i + 1)} href="#">
                  {i + 1}
                </MDBPaginationLink>
              </MDBPaginationItem>
            ))}
          </MDBPagination> */}
        </DashboardBoxStyle>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Offcanvas, Row } from "react-bootstrap";
import { IoIosMail, IoMdNotifications } from "react-icons/io";
import { NavbarStyled } from "./NavbarStyles";
import user from "assets/image/userDummy.jpeg";
import "./Navbar.css";
import { CollegeSidePanelData } from "contants/CollegeSidePanelData";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/image/logo.svg";
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { GetCollege } from "../../axios/apis";
import Cookies from "js-cookie";

export const NavbarCollege = ({ updateprofile }) => {
  const location = useLocation();
  const name = Cookies.get("name");
  const email = Cookies.get("email");
  const profileImg = Cookies.get("profileImg");
  var myDate = new Date();
  var hrs = myDate.getHours();
  const navigate = useNavigate()
  const [greet, setGreet] = useState("Good Morning");
  const [userDetail,setUserDetail] = useState({})
  useEffect(() => {
    if (hrs < 12) setGreet("Good Morning");
    else if (hrs >= 12 && hrs <= 17) setGreet("Good Afternoon");
    else if (hrs >= 17 && hrs <= 24) setGreet("Good Evening");
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      navigate("/");
    }
  };
  const GetCollegeDetails = async () => {
    try {
      const response = await GetCollege();
      setUserDetail(response?.data?.data);
      // console.log(response);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
    }
  };
  useEffect(() => {
    GetCollegeDetails();
  }, [updateprofile]);
  return (
    <>
      <NavbarStyled>
        <div className="nav-sec-1 d-none-968">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h4 className="nav-head">
                Hi, {userDetail?.collegename}
              </h4>
            </div>
          </div>
          <Row>
            <div className="d-flex align-items-center ">
              <IoIosMail size={24} />
              <p className="nav-para">{userDetail?.email}</p>
            </div>
          </Row>
        </div>
        <div className="nav-sec-1 d-flex-968">
          <AiOutlineMenu onClick={handleShow} className="c-pointer" size={30} />
          <Offcanvas className="sidebar-968 " show={show} onHide={handleClose}>
            <Offcanvas.Header className="py-1">
              <div className="m-auto">
    
                <img style={{ width: "160px" }} src={logo} />
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="px-3 py-4">
                <div className="min-height-sidebar">
                  {CollegeSidePanelData.slice(0, 5).map((item) => (
                    <NavLink
                      onClick={handleClose}

                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`
                        d-flex align-items-center py-2 wrapper__sidebar`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <hr />
                  {CollegeSidePanelData.slice(5, 8).map((item) => (
                    <NavLink
                      onClick={handleClose}

                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`d-flex align-items-center py-2 wrapper__sidebar`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <a className="sidebar__link" href="https://viosa.in/">
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar

                  cursor-pointer"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2">Go To Dashboard</h6>
                    </div>

                  </a>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="d-flex justify-content-evenly align-items-center nav-sec-2">
          {/* <div className="renew">
            <h6>Renew</h6>{" "}
          </div> */}
          <NavLink
            to={"/college/notifications"}
            className={`nav-notification ${location.pathname === "/notifications" ? "active" : ""
              }`}
          >
            <IoMdNotifications size={28} />
          </NavLink>
          <div className="user-img-cover">
            <img className="user-img" src={userDetail?.profileImg || user} alt="user" />
          </div>
        </div>
      </NavbarStyled>
    </>
  );
};

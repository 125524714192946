import {
  ButtonYellowFill,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import JolPlayer from "jol-player";
import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import sample from "assets/sample.mp4";
import botposter from "assets/image/bot-poster.png";
import { useNavigate, useParams } from "react-router";
import Footer from "../Footer";
import Header from "../Header";
import { InterviewBoxStyled, InterviewPageBox } from "../InterviewStyled";
import Feedback from "./Feedback";
import FinalReviewAns from "./FinalReviewAns";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Webcam from "react-webcam";
import { GetSingleInterview, InterviewAnalysis } from "../../../axios/apis";
import { webmFixDuration } from "webm-fix-duration";
import "./videoInterview.css";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";
import axios from "axios";
import { createRoot } from "react-dom/client";
import ReactPlayer from "react-player";

export const VideoPage2 = () => {
  const [time, setTime] = useState(0);
  const [questionNo, setQuestionNo] = useState(1);
  const [recordedAnsno, setRecordedAnsNo] = useState(1);
  const [recordvideo, setRecordvideo] = useState(false);
  const [show, setShow] = useState(false);
  const [submitpage, setSubmitpage] = useState(false);
  const [feedbackpage, setFeedbackpage] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [running, setRunning] = useState(false);
  const [videoreplay, setvideoReplay] = useState(false);
  const [recordbtn, setrecordbtn] = useState(false);
  const [questionduration, setQuestionDuration] = useState(5000);
  const [video, setvideo] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  let urlsLength = 0;
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [questions, setquestions] = useState([]);
  const [videoLoaded, setVideoLoaded] = useState(true);
  const [testvideo, setTestvideo] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const name = Cookies.get("name");
  const role = Cookies.get("role");
  const logintype = Cookies.get("logintype");
  const webcamRef = useRef(null);
  const recordedChunks = useRef([]);
  const mediaRecorderRef = useRef(null);
  const videoplayerRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [starttime, setStartTime] = useState(Date.now());
  const [windowsize, setwindowsize] = useState({
    height: window.innerHeight - 300,
    width: window.innerWidth - 100,
  });
  const videoConstraints = {
    facingMode: "user",
    borderRadius: "20px",
  };
  const handleLoadedMetadata = (e) => {
    e.preventDefault();
    console.log("loaded");
    const video = videoplayerRef.current;
    if (!video) return;
    // setQuestionDuration(video.duration * 1000);
    setQuestionDuration(video.duration);
    setVideoLoaded(false);
  };
  console.log(videoLoaded);
  useEffect(() => {
    const handleWindowResize = () => {
      let { innerWidth, innerHeight } = window;
      setwindowsize({
        ...windowsize,
        height: innerHeight - 300,
        width: innerWidth - 100,
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  // console.log(video);
  urlsLength = videoUrl.length;
  console.log(videoplayerRef.current);
  console.log(urlsLength);
  console.log(videoUrl);

  const finalSubmitNew = async () => {
    setFeedbackpage(true);
    setFeedbackLoading(true);
    // console.log(videoUrl, video, urlsLength)
    if (video.length === urlsLength) {
      mediaRecorderRef.current.stop();
      try {
        const formdata = new FormData();
        formdata.append("type", role === "student" ? "Student" : "Interviewee");
        formdata.append("interviewQuestions", JSON.stringify(questions));
        formdata.append("interviewId", params.id);
        for (var i = 0; i < video.length; i++) {
          formdata.append("video", video[i]);
        }

        const newdata = {
          type: role === "student" ? "Student" : "Interviewee",
          interviewQuestions: JSON.stringify(questions),
          interviewId: params.id,
          videourls: videoUrl,
        };

        const res = await InterviewAnalysis(newdata);
        if (role === "interviewee") {
          navigate("/interviewee/dashboard");
        } else {
          navigate("/college-student/dashboard");
        }
        // debugger
        setFeedbackLoading(false);
        // }
      } catch (error) {
        // console.log(error);
        toast.error("Try Again", ToastStyles);
        setFeedbackpage(false);
        setFeedbackLoading(false);
      }
    } else {
      setTimeout(() => {
        finalSubmit();
      }, 5000);
      return;
    }
  };
  const finalSubmit = async () => {
    // urlsLength= videoUrl.length

    console.log(videoUrl, video, urlsLength);
    if (video.length === urlsLength) {
      setFeedbackpage(true);
      setFeedbackLoading(true);
      mediaRecorderRef.current.stop();
      try {
        const formdata = new FormData();
        formdata.append("type", role === "student" ? "Student" : "Interviewee");
        formdata.append("interviewQuestions", JSON.stringify(questions));
        formdata.append("interviewId", params.id);
        for (var i = 0; i < video.length; i++) {
          formdata.append("video", video[i]);
        }

        const newdata = {
          type: role === "student" ? "Student" : "Interviewee",
          interviewQuestions: JSON.stringify(questions),
          interviewId: params.id,
          videourls: videoUrl,
        };
        console.log("video url", videoUrl);

        const res = await InterviewAnalysis(newdata);
        if (role === "interviewee") {
          navigate("/interviewee/dashboard");
        } else {
          navigate("/college-student/dashboard");
        }
        // Show the toast after a timeout, considering the redirection
        console.log("Toast message before navigation");
        setTimeout(() => {
          toast.success(
            "Check your report in 5 mins in the interview report section."
          );
          console.log("Toast message after navigation");
        }, 3000);

        // debugger
        setFeedbackLoading(false);
        // }
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setFeedbackpage(false);
        setFeedbackLoading(false);
      }
    } else {
      setLoadingModal(true);
      setSeconds(60);
      setTimeout(() => {
        setLoadingModal(false);
        // finalSubmit()
      }, 60000);
      return;
    }
  };
  console.log(videoUrl);

  const UploadVideo = async () => {
    if (video.length) {
      // setLoadingIconupload(true);
      console.log("video url", videoUrl);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          // setPercentage(percentange);
          if (percentange === 100) {
            // setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", video[video.length - 1]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      // setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          console.log(data.data.link);
          setVideoUrl((prev) => [...prev, data.data.link]);
          // setLoadingIconupload(false);
          // toast.success("Image Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          // setLoadingIconupload(false);
        });
    }
  };

  const blobToFile = (videoBlob, fileName, fileExtension) => {
    return new File([videoBlob], `${fileName}.${fileExtension}`, {
      lastModified: new Date().getTime(),
      type: videoBlob.type,
    });
  };
  const handleStartCaptureClick = (e) => {
    e.preventDefault();
    setRunning(true);
    toast.success("Recording Started", ToastStyles);
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, {
          type: 'video/mp4; codecs="avc1.424028, mp4a.40.2"',
          audioBitsPerSecond: 5120000,
          videoBitsPerSecond: 5120000,
          dataBitsPerSecond: 5120000,
        });
        const chunks = [];
        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          console.log(event);
          chunks.push(event.data);
        });
        mediaRecorderRef.current.addEventListener("stop", async (e) => {
          // console.log(e);
          let videoBlob = new Blob(chunks, { type: "video/webm" });
          const duration = Date.now() - starttime;
          console.log(Date.now());
          console.log(starttime);

          console.log(duration);
          let videoBlob2 = await webmFixDuration(videoBlob, duration);
          const filename = videoBlob2.size;
          console.log("videoblob2:");
          console.log(videoBlob2);
          let newdata = blobToFile(videoBlob2, Date.now(), "mp4");
          // UploadVideo(newdata)
          setvideo((prevVideo) => [...prevVideo, newdata]);
          recordedChunks.current = [];
          setTestvideo([...testvideo, URL.createObjectURL(videoBlob)]);
          chunks.length = 0;
        });
        mediaRecorderRef.current.start();
        setStartTime(Date.now());
        setCapturing(true);
        setBtnDisable(true);
        setTimeout(() => {
          setBtnDisable(false);
        }, 15000);
      });
  };
  const handleStopCaptureClick = (e) => {
    // Ensure that the event object is available before using it
    e && e.preventDefault();
    const duration = Date.now() - starttime;
    console.group(Date.now());
    console.log(duration);
    console.log("Video Duration:", questions[questionNo - 1]?.duration);

    if (capturing !== true) {
      // toast.error("Start the Recording First", ToastStyles);
    } else if (duration < 15000) {
      toast.error("Video must be greater than 15 seconds", ToastStyles);
    } else {
      setvideoReplay(false);
      setRunning(false);
      mediaRecorderRef.current?.stop();
      setCapturing(false);
      setRecordvideo(true);
    }
  };

  const recordagainstart = () => {
    setQuestionNo(1);
    setvideo([]);
    setVideoUrl([]);
    setTestvideo([]);
    setSubmitpage(false);
    setRecordedAnsNo(1);
    handleClose();
  };
  const RecordThisAgain = () => {
    setRecordvideo(false);
    let newvideoes = video;
    let newtestvideoes = testvideo;
    newvideoes.pop();
    newtestvideoes.pop();
    setvideo(newvideoes);
    setTestvideo(newtestvideoes);
    handleClose();
  };
  const date = new Date(null);
  const date2 = new Date(null);
  date2.setSeconds(questions[questionNo - 1]?.duration);
  date.setSeconds(time);
  // console.log(time)
  useEffect(() => {
    if (time >= questions[questionNo - 1]?.duration) {
      toast.error("Timeup", ToastStyles);
      handleStopCaptureClick();
    }
  }, [time]);

  useEffect(() => {
    let interval;
    if (running) {
      date.setSeconds(0);
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
        // Check if the timer has reached zero
        if (minutes === 0 && seconds === 0) {
          // Call the function to stop recording
          handleStopCaptureClick();
        }
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
      setTime(0);
    }
    return () => clearInterval(interval);
  }, [running]);

  // console.log()
  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      setquestions(response.data.interview.interviewquestions);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetInterviewDetails();
  }, [params.id]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // setResendActive(false);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  return (
    <>
      <Modal
        show={loadingModal}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="text-center">
          Please wait while we're submmiting your Response.
          <h4 className="text-center my-4">00 : {seconds}</h4>
        </Modal.Body>
      </Modal>
      <Toaster />
      <Header />
      {loading ? (
        <>
          <InterviewBoxStyled className=" interview-form d-flex scroller h-70 padding-0 align-items-center justify-content-center">
            <Loading />
          </InterviewBoxStyled>
        </>
      ) : (
        <>
          <InterviewBoxStyled className="scroller interview-form h-70 padding-0 ">
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5> Are you sure you want to record again?</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h6>
                  {" "}
                  Do take note that this will require you to re-do the entire
                  interview from question 1. Press the button Record Again to
                  restart, or you can close this window and proceed to submit
                  your responses.
                </h6>
              </Modal.Body>
              <Modal.Footer>
                <ButtonYellowOutline
                  variant="secondary"
                  onClick={recordagainstart}
                >
                  Record Again
                </ButtonYellowOutline>
                <ButtonYellowFill width="120px" onClick={handleClose}>
                  Close
                </ButtonYellowFill>
              </Modal.Footer>
            </Modal>
            {!feedbackpage && (
              <>
                {!submitpage && (
                  <InterviewPageBox className=" page-box px-5">
                    <div className="col-12">
                      {recordvideo ? (
                        <>
                          <div className="section-1 my-3 w-100 d-flex flex-column px-3">
                            <div className="d-flex justify-content-center mt-3">
                              <div className="col-md-6">
                                <div className="px-3 d-flex justify-content-center flex-column">
                                  <h6 className="text-center">Hey! {name}</h6>
                                  <h6 className="text-center">
                                    Check Your Video
                                  </h6>
                                  <div className=" video-player pb-4">
                                    {" "}
                                    {/* <JolPlayer
                                      className="player m-auto"
                                      option={{
                                        height: 250,
                                        mode: "scaleToFill",
                                        width: 350,
                                        language: "en",
                                        controls: true,
                                        loop: false,
                                        setBufferContent: "Loading...",
                                        videoType: "video/mp4",
                                        nextButton: true,
                                        prevButton: true,
                                        pausePlacement: "center",
                                        isShowWebFullScreen: true,
                                        isProgressFloat: true,
                                        videoSrc:
                                          testvideo[testvideo?.length - 1],
                                      }}
                                    /> */}
                                    <ReactPlayer
                                      url={testvideo[testvideo?.length - 1]}
                                      controls={true}
                                      width="100%"
                                      height="100%"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center my-3">
                                    <ButtonYellowOutline
                                      onClick={RecordThisAgain}
                                      style={{ width: "350px" }}
                                    >
                                      Record Again
                                    </ButtonYellowOutline>
                                  </div>
                                  <div className="d-flex justify-content-center my-3">
                                    <ButtonYellowFill
                                      onClick={() => {
                                        setVideoLoaded(true);
                                        setRecordvideo(false);
                                        if (questionNo === questions.length) {
                                          UploadVideo();
                                          setSubmitpage(true);
                                          // window.location.reload()
                                        } else {
                                          setQuestionNo((prev) => prev + 1);
                                          UploadVideo();
                                        }
                                      }}
                                      width="350px"
                                    >
                                      Okay, Let's Proceed
                                    </ButtonYellowFill>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="section-1 video-page-2  w-100 d-flex flex-column px-3">
                            <div className="d-flex ">
                              <h5 className="my-2 text-center lh-20">
                                Q.{questionNo}&nbsp;/&nbsp;Q.{questions.length}
                                &nbsp;
                                {questions.length > 0 &&
                                  questions[questionNo - 1]?.question}
                              </h5>
                              {/* <div className="d-flex justify-content-center  flex-column">
                            <h6 className="purple text-center mb-0 lh-20">
                              Complete Answer in {questions.length > 0 && questions[questionNo - 1]?.duration} Seconds
                            </h6>
                            <h6 className="yellow text-center mb-0 lh-20">
                              <span
                              className={time > questions[questionNo - 1]?.duration ? "color-red":""}>{time}</span>/{questions.length > 0 && questions[questionNo - 1]?.duration}
                            </h6>
                          </div> */}
                            </div>
                            <div className="d-flex video-box gap-1 mt-1">
                              {/* {
                                !videoLoaded ? ( */}
                              <div className="col-6 video-res justify-content-center">
                                <div className="">
                                  <div className="video-player video-cam-box">
                                    <video
                                      controls={false}
                                      className="player m-auto d-block"
                                      style={{
                                        height: windowsize.height,

                                        width: windowsize.height,
                                        objectFit: "fill",
                                      }}
                                      ref={videoplayerRef}
                                      onLoadedMetadata={handleLoadedMetadata}
                                      preload="auto" // Add preload attribute
                                      loading="lazy" // Add lazy loading attribute
                                      src={
                                        questions[questionNo - 1]
                                          ?.videoQuestion ||
                                        "https://viosa-storage-2.s3.amazonaws.com/Sequence%2001_1.mp4"
                                      }
                                    ></video>{" "}
                                  </div>
                                </div>
                              </div>
                              {/* {videoLoaded &&
                                <div className="col-6 d-flex align-items-center justify-content-center ">
                                  <Spinner size='lg' animation="border" />
                                </div>

                              } */}

                              <div className="col-6 video-res">
                                <div className="">
                                  <div className="mx-auto video-cam-box width-set">
                                    {" "}
                                    <div className="Container">
                                      <div className="d-flex justify-content-center main-video">
                                        <Webcam
                                          style={{ objectFit: "cover" }}
                                          width={windowsize.width / 2}
                                          height={windowsize.height}
                                          audio={true}
                                          mirrored={true}
                                          muted={true}
                                          ref={webcamRef}
                                          videoConstraints={videoConstraints}
                                        />
                                        {/* <ReactRecorder/> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end content-center">
                              <div className="d-flex justify-content-center  flex-column">
                                <h6 className="purple text-center mb-0 lh-20">
                                  {/* Complete Answer in{" "}
                                  {questions.length > 0 &&
                                    questions[questionNo - 1]?.duration}{" "}
                                  Seconds */}
                                  Answer for minimum 15 seconds
                                </h6>
                                <h6 className="yellow text-center mb-0 lh-20">
                                  <span
                                    className={
                                      time - 5 >
                                      questions[questionNo - 1]?.duration
                                        ? "color-red"
                                        : ""
                                    }
                                  >
                                    {date.toISOString().substr(11, 8)}
                                  </span>
                                  /
                                  {questions.length > 0 &&
                                    date2.toISOString().substr(11, 8)}
                                </h6>
                              </div>
                            </div>
                            <div className="d-flex justify-content-around button-box my-2">
                              <form>
                                {videoreplay ? (
                                  <>
                                    {capturing ? (
                                      <ButtonYellowFill
                                        data-toggle={`${
                                          btnDisable ? "tooltip" : "none"
                                        }`}
                                        data-placement="bottom"
                                        title={`${
                                          btnDisable
                                            ? "Video must be greater than 15 seconds"
                                            : ""
                                        }`}
                                        width="150px"
                                        disabled={btnDisable}
                                        onClick={handleStopCaptureClick}
                                        className="h-40 bg-red"
                                      >
                                        Stop Recording
                                      </ButtonYellowFill>
                                    ) : (
                                      <ButtonYellowFill
                                        onClick={handleStartCaptureClick}
                                        width="150px"
                                        className="h-40 bg-green"
                                      >
                                        Start Recording
                                      </ButtonYellowFill>
                                    )}
                                  </>
                                ) : (
                                  <ButtonYellowFill
                                    width="150px"
                                    disabled={btnDisable || videoLoaded}
                                    className="h-40 bg-green"
                                    // onEndEd = {setvideoReplay(true)}
                                    onClick={() => {
                                      setTimeout(() => {
                                        setvideoReplay(true);
                                        setBtnDisable(false);
                                      }, questionduration);
                                      setBtnDisable(true);
                                      videoplayerRef.current.play();
                                    }}
                                  >
                                    {btnDisable
                                      ? "Playing..."
                                      : "Play Question"}
                                  </ButtonYellowFill>
                                )}
                              </form>

                              {/* {
                            questionNo === questions.length ? (<ButtonYellowFill
                              onClick={() => {
                                if (testvideo.length > 0) {

                                  setSubmitpage(true);
                                } else {
                                  toast.error("Please Record answer First", ToastStyles)
                                }
                              }}
                              disabled={capturing}

                              width="150px"
                            >
                              Submit
                            </ButtonYellowFill>) : (<ButtonYellowFill
                              disabled={capturing}

                              onClick={() => {
                                setQuestionNo((prev) => prev + 1)
                              }}
                              width="150px"
                            >
                              Skip
                            </ButtonYellowFill>)
                          } */}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </InterviewPageBox>
                )}
                {submitpage && (
                  <FinalReviewAns
                    handleShow={handleShow}
                    testvideo={testvideo}
                    video={video}
                    questions={questions}
                    setRecordedAnsNo={setRecordedAnsNo}
                    recordedAnsno={recordedAnsno}
                    finalSubmit={finalSubmit}
                  />
                )}
              </>
            )}
            {feedbackpage && (
              <>
                <Feedback feedbackLoading={feedbackLoading} />
              </>
            )}
            <Footer />
          </InterviewBoxStyled>
        </>
      )}
    </>
  );
};

import { create_UUID } from "helper/uuid";
import tick from "assets/image/tick.png";
import time from "assets/image/time.png";
import job from "assets/image/job.png";
import heart from "assets/image/heartblue.png";
import sample from "assets/image/sample2.png";
import {
  MdAssessment,
  MdAssignment,
  MdEmojiObjects,
  MdGesture,
  MdQuestionAnswer,
} from "react-icons/md";
import { RiLightbulbFlashFill } from "react-icons/ri";

export const CreateInterviewMockData = [
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
  {
    id: create_UUID(),
    name: "Sound artist Interview",
    image: sample,
    icon: heart,
    icons: [
      {
        name: "Interview",
        icon: tick,
      },
      {
        name: "15 Minutes",
        icon: time,
      },
      {
        name: "Entry Level",
        icon: job,
      },
    ],
    description:
      "Should have an eye for clean and artful design and possess superior UI skills to translate READ MORE",
  },
];

export const createInterViewLeftData = [
  {
    id: create_UUID(),
    icon: <MdAssessment color="" />,
    name: "New Job Interview",
  },
  {
    id: create_UUID(),
    icon: <MdEmojiObjects />,
    name: "Add job Skills",
  },
  {
    id: create_UUID(),
    icon: <MdQuestionAnswer />,
    name: "Add Interview questions ",
  },
  {
    id: create_UUID(),
    icon: <MdAssignment />,
    name: "Ask list of documents",
  },
];

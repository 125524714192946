import React from "react";
import { SidePanelPrimaryBox, SidePanelSecondaryBox } from "./SidepanelStyled";
import logo from "assets/image/logo.svg";
import logosmall from "assets/image/logosmall.png";
import Cookies from "js-cookie";
export const SidePanel = ({ children, hideSidebar }) => {
  const role = Cookies.get("role")
  return (
    <div className="sidebar ">
      <SidePanelSecondaryBox className="aligncenter">
        {hideSidebar ? <img className="small-logo" src={logosmall} /> : <img src={logo} />}
      </SidePanelSecondaryBox>
      <SidePanelPrimaryBox className={`${role === "corporate" ? "bg-purple" : ""
        }`}>{children}</SidePanelPrimaryBox>
    </div>
  );
};

import React, { useState } from "react";
import { Chartbar } from "components/Bar/Chartbar";
import joblogo from "assets/image/job-logo.png";
import {
  BoxStyle,
  ButtonPurpleOutline,
  ButtonYellowOutline,
} from "components/common/CommonStyles";
import "./RecentjobCard.css";
import { BsThreeDots } from "react-icons/bs";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useNavigate } from "react-router";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
const RecentjobCard = ({ data, DeleteInterview }) => {
  const [showgraph, setShowgraph] = useState(false);
  const navigate = useNavigate();

  return (
    <BoxStyle className="padding-5 shadow-2 my-3">
      <div className="job-card">
        {/* <div className="job-card-sec1 col-2 col-md-1 col-sm-1">
          <img className="col-10 br-50" src={data?.icon} alt="png" />
        </div> */}
        <div className="job-card-sec2 col-8 col-sm-5 col-md-4 ">
          <h5 className="title">{data?.jobtitle}</h5>
          <p className="desc">
            {data?.jobcategory}
            <span
              onClick={() => {
                navigate(`/hire-pro/my-interviews/${data?._id}`);
              }}
              className="c-pointer read-more"
            >
              {" "}
              Read More{" "}
            </span>
          </p>
        </div>
        <div className="job-card-sec3 justify-content-evenly col-sm-4 col-md-3  align-items-center d-none d-sm-flex">
          <div className="vertical-row"></div>
          <div>
            <h5>
              No of candidates{" "}
              <span className="yellow">{data?.interviewResult.length}</span>
            </h5>
            <h5>
              No of Resume{" "}
              <span className="yellow">{data?.interviewResult.length}</span>
            </h5>
          </div>
        </div>
        <div className="job-card-sec4 justify-content-evenly col-3 d-none d-md-flex align-items-center">
          <div className="vertical-row"></div>
          <div>
            <h5>
              Status <span className="">🟢</span>
            </h5>
            <h5>
              Shortlisted <span className="yellow">0</span>
            </h5>
          </div>
        </div>
        <div className="job-card-sec5 d-flex justify-content-evenly flex-col col-2 col-md-1 col-sm-1 align-items-center">
          <div className="vertical-row"></div>
          <div>
            <div
              className="more-icon c-pointer"
              onClick={() => {
                navigate(`/hire-pro/edit-interview/${data?._id}`);
              }}
            >
              <AiOutlineEdit />
            </div>
            <div
              className="down-icon c-pointer"
              onClick={() => {
                DeleteInterview(data);
              }}
            >
              <AiOutlineDelete />
            </div>
          </div>
        </div>
      </div>
      {showgraph && (
        <div className="mt-2">
          <hr />
          <div className="m-2 d-flex justify-content-between align-items-center">
            <div className="live">
              <h6> 🟢 Live</h6>
            </div>
            <div className="live-analytics">
              <ButtonPurpleOutline className="me-3">Today</ButtonPurpleOutline>
              <select className="analytics-drop" name="analytics" id="">
                <option value="1">Today</option>
                <option value="2">Yesterday</option>
                <option value="3">This Week</option>
                <option value="4">This Month</option>
              </select>
            </div>
          </div>
          <Chartbar />
        </div>
      )}
    </BoxStyle>
  );
};

export default RecentjobCard;

import React, { useEffect, useState } from "react";
import { BoxStyle, DashboardBoxStyle, YellowHeading } from "components/common/CommonStyles";
import "./interview.css";

import { GetAllInterview, GetStudentsDetails } from "../../../axios/apis";
import PracticeInterviewCard from "components/Card/PracticeInterviewCard";
import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Loading from "components/common/Loading";
import { useParams } from "react-router";
import { AiOutlineSearch } from "react-icons/ai";
import Cookies from "js-cookie";

export const PracticeInterview = () => {
  const [interviewteam, setInterViewteam] = useState();
  const [allinterview, setAllInterView] = useState([]);
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState("")
  
  const GetUser = async () => {
    setLoading(true);
    try {
      let interviewsArr = [];
      const res = await GetStudentsDetails();
      let interviews = res?.data?.data?.teams?.map((item, index) => (

        item?.interview?.map((data, i) => (
          interviewsArr.push(data)
        ))))
      console.log(interviewsArr)
      setInterViewteam(interviewsArr);
      setAllInterView(interviewsArr);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  useEffect(() => {
    GetUser();
  }, []);

  const params = useParams()

  const handleSearch = (e) => {
    const searched = e.target.value;
    setSearch(e.target.value)
    if (searched === "") {
      setInterViewteam(allinterview)
    }
    else {
      const searchInterview = allinterview.filter(interview => interview.jobtitle.toLowerCase().includes(searched.toLowerCase()))
      setInterViewteam(searchInterview)
    }
  }

  useEffect(() => {
    if (params?.id) {
      Cookies.set("token", params.id)
      Cookies.set("role", "student");
    }
    GetUser();
  }, [params.id]);


  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />

        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <div className="row">
              <YellowHeading>List of Interviews</YellowHeading>
              <div className="px-2 mb-3">

                <Form.Control
                  className="form-input"
                  name="search"
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
                <div className="eye-position">
                  <AiOutlineSearch
                    className="password-eye1 pe-1"
                    color="#602a94"
                    size={28}
                  />
                </div>
              </div>

            </div>
            <div className="row">


              <div className="mt-0">
                <div>
                  {/* {interviewteam?.map((item, index) => (

                      item?.interview?.map((data, i) => (
                        <PracticeInterviewCard key={index} item={data} />
                      ))
                    ))} */}
                  {interviewteam?.length > 0 ? (<>{interviewteam?.map((data, i) => (
                    <PracticeInterviewCard key={i} item={data} />
                  ))
                  }</>) : (<>
                    <div className="my-3">
                      <h5>Currentlty no Interviews to Show.</h5>

                    </div>
                  </>)}

                </div>
              </div>
            </div>
          </div>
        </DashboardBoxStyle>)}
    </>
  );
};

import {
  CollegeLogin,
  CorporateLogin,
  LoginAll,
  StudentLogin,
  SuperAdminLogin,
} from "../../axios/apis";
import {
  ButtonBlue,
  DarkHeading,
  Input,
  Select,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillLock,
  AiOutlineUser,
} from "react-icons/ai";
import { FaUserFriends } from "react-icons/fa";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { useNavigate } from "react-router";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "../common/ToastStyle";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
export const Login = ({ setSelect }) => {
  const [showpassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [queryData] = useSearchParams();
  const [data, setData] = useState({
    email: "",
    password: "",
    role: "",
    invitecode: "",
  });
  const inputHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (data.role === "0") {
      alert("Please select role");
      return;
    } else if (data.role === "student") {
      setLoading(true);
      const { email, password } = data;
      const formdata = {
        email,
        password,
      };
      try {
        const res = await StudentLogin(formdata);
        // console.log(res?.data?.data?.student);
        setLoading(false);
        if (!res.data.data.student) {
          alert("Invalid Credentials");
        } else {
          Cookies.set("token", res?.data?.token);
          Cookies.set("role", res?.data?.type);

          Cookies.set("name", res?.data?.data?.student?.firstname);
          Cookies.set("email", res?.data?.data?.student?.email);
          Cookies.set("profileImg", res?.data?.data?.student?.profile);
          navigate("/college-student/dashboard");
        }
      } catch (error) {
        setLoading(false);
        // console.log(error);
        if (error.response.status === 400) {
          alert("Invalid Credentials");
        } else if (error.response.status === 404) {
          alert("User Doesn't Exist");
        } else {
          alert("Error in Login");
        }
        setLoading(false);
      }
    } else if (data.role === "college") {
      setLoading(true);
      const { email, password } = data;
      const formdata = {
        email,
        password,
      };
      try {
        const res = await CollegeLogin(formdata);
        // console.log(res?.data?.data?.college);
        setLoading(false);
        if (!res.data.data.college) {
          alert("Invalid Credentials");
        } else {
          Cookies.set("token", res?.data?.token);
          Cookies.set("role", res?.data?.type);

          Cookies.set("collegeID", res?.data?.data?.college?._id);
          Cookies.set("name", res?.data?.data?.college?.collegename);
          Cookies.set("email", res?.data?.data?.college?.email);
          Cookies.set("profileImg", res?.data?.data?.college?.profileImg);
          navigate("/college/dashboard");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log(error);
        if (error.response.status === 400) {
          alert("Invalid Credentials");
        } else if (error.response.status === 404) {
          alert("User Doesn't Exist");
        } else {
          alert("Error in Login");
        }
        setLoading(false);
      }
    } else if (data.role === "superadmin") {
      setLoading(true);
      const { email, password } = data;
      const formdata = {
        email,
        password,
      };
      try {
        const res = await SuperAdminLogin(formdata);
        // console.log(res?.data?.data?.admin);
        setLoading(false);

        if (!res.data.data.admin) {
          alert("Invalid Credentials");
        } else {
          Cookies.set("token", res?.data?.token);
          Cookies.set("role", res?.data?.type);

          Cookies.set("userDetails", res?.data?.data?.admin);
          navigate("/superadmin/dashboard");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response.status === 400) {
          alert("Invalid Credentials");
        } else if (error.response.status === 404) {
          alert("User Doesn't Exist");
        } else {
          alert("Error in Login");
        }
        setLoading(false);
      }
    } else if (data.role === "corporate") {
      setLoading(true);
      const { email, password } = data;
      const formdata = {
        email,
        password,
      };
      try {
        const res = await CorporateLogin(formdata);
        // console.log(res?.data?.data?.corporate);
        setLoading(false);

        if (!res.data.data.corporate) {
          alert("Invalid Credentials");
        } else {
          Cookies.set("token", res?.data?.token);
          Cookies.set("role", res?.data?.type);

          Cookies.set("userDetails", res?.data?.data?.corporate);
          navigate("/hire-pro/dashboard");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.log(error);
        if (error.response.status === 400) {
          alert("Invalid Credentials");
        } else if (error.response.status === 404) {
          alert(error.response.data.message);
        } else {
          alert("Error in Login");
        }
        setLoading(false);
      }
    }
  };
  const handleLogin2 = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = data;
    const formdata = {
      email,
      password,
    };
    try {
      const res = await LoginAll(formdata);
      // console.log(res);
      setLoading(false);
      // debugger;
      let role = res.data.type;
      if (role === "college") {
        Cookies.set("token", res?.data?.token);
        Cookies.set("role", res?.data?.type);

        Cookies.set("name", res?.data?.data?.college?.collegename);
        Cookies.set("email", res?.data?.data?.college?.email);
        Cookies.set("profileImg", res?.data?.data?.college?.profileImg);
        Cookies.set("collegeID", res?.data?.data?.college?._id);
        navigate("/college/dashboard");
      } else if (role === "student") {
        Cookies.set("token", res?.data?.token);
        Cookies.set("role", res?.data?.type);

        Cookies.set("name", res?.data?.data?.student?.firstname);
        Cookies.set("email", res?.data?.data?.student?.email);
        Cookies.set("profile", res?.data?.data?.student?.profileImg);
        navigate("/college-student/dashboard");
      } else if (role === "corporate") {
        Cookies.set("token", res?.data?.token);
        Cookies.set("role", res?.data?.type);

        Cookies.set("name", res?.data?.data?.corporate?.company);
        Cookies.set("email", res?.data?.data?.corporate?.email);
        Cookies.set("profileImg", res?.data?.data?.corporate?.profileImg);
        navigate("/hire-pro/dashboard");
      } else if (role === "admin") {
        Cookies.set("token", res?.data?.token);
        Cookies.set("role", res?.data?.type);

        Cookies.set("name", res?.data?.data?.admin?.firstname);
        Cookies.set("email", res?.data?.data?.admin?.email);
        Cookies.set("profileImg", res?.data?.data?.admin?.profileImg);
        navigate("/superadmin/dashboard");
      } else if (role === "interviewee") {
        Cookies.set("token", res?.data?.token);
        Cookies.set("role", res?.data?.type);
        Cookies.set("name", res?.data?.data?.interviewee?.firstname);
        Cookies.set("email", res?.data?.data?.interviewee?.email);
        Cookies.set("profileImg", res?.data?.data?.interviewee?.profile);
        navigate("/interviewee/dashboard");
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
      alert(error.response.data.error || error.response.data.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const tokenData = queryData.get("token");
    const roleData = queryData.get("role");
    const hireProRole = queryData.get("hireProRole");
    if (tokenData && tokenData.length > 0 && roleData) {
      Cookies.set("role", hireProRole);
      Cookies.set("token", tokenData);
    }
    if (tokenData && roleData === "admin") {
      navigate("/superadmin/dashboard");
    }
    else if (tokenData && roleData === "college") {
      navigate("/college/dashboard");
    } else if (tokenData && roleData === "corporate") {
      navigate("/hire-pro/dashboard");
    } else if (tokenData && hireProRole === "student") {
      navigate("/college-student/dashboard");
    } else if (tokenData && hireProRole === "interviewee") {
      navigate("/interviewee/dashboard");
    }
  }, []);
  return (
    <>
      <Toaster />
      <Form onSubmit={handleLogin2}>
        <div className="mb-3 mt-3">
          {/* <div className="d-flex align-items-center">
            <FaUserFriends className="input-icon" color="#ffa303" size={28} />
            <Select
              className="mb-3 px-5 text-purple br-5"
              name="role"
              onChange={(e) => inputHandler(e)}
            >
              <option value="0">Select Role</option>
              <option value="student">Student</option>
              <option value="corporate">Corporate</option>
              <option value="college">College</option>
            </Select>
          </div> */}
          <div className="d-flex align-items-center">
            <AiOutlineUser className="input-icon" color="#ffa303" size={28} />
            <Input
              placeholder="Email"
              name="email"
              type="email"
              required
              autoComplete="email"
              className="mb-3 px-5 br-5"
              onChange={(e) => inputHandler(e)}
            ></Input>
          </div>
          <div className="d-flex align-items-center ">
            <AiFillLock className="input-icon" color="#ffa303" size={28} />
            <Input
              placeholder="Password"
              name="password"
              autoComplete="password"
              minLength={5}
              className="mb-3 px-5 br-5 password"
              type={showpassword ? "text" : "password"}
              required
              onChange={(e) => inputHandler(e)}
            ></Input>
            {showpassword ? (
              <AiFillEyeInvisible
                onClick={() => setShowPassword(false)}
                className="password-eye pe-1"
                color="#ffa303"
                size={28}
              />
            ) : (
              <AiFillEye
                onClick={() => setShowPassword(true)}
                className="password-eye pe-1"
                color="#ffa303"
                size={28}
              />
            )}
          </div>

          <DarkHeading className="fs-12 mb-0 inline-block">
            <input type="checkbox" className="me-2 " />
            Remember Me {" "}
          </DarkHeading>
          {loading ? (
            <>
              <ButtonBlue className="w-100 h-40 box-shadow" disabled>
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            </>
          ) : (
            <>
              <ButtonBlue className="w-100 h-40 box-shadow" type="submit">
                Sign In
              </ButtonBlue>
            </>
          )}
          <DarkHeading onClick={() => { setSelect(2) }} className="fs-12 mb-0 inline-block">
            Forget Password ?
          </DarkHeading>
          {/* <div className="d-flex justify-content-end my-2">
        <ButtonBlue
          color="white"
          className="w-10 h-40 box-shadow px-3"
          onClick={() => setSelect(1)}
        >
          Sign Up
        </ButtonBlue>
      </div> */}
        </div>
      </Form>
    </>
  );
};

import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { tokens } from "./theme";
import { mockDataInvoices } from "./Mockdata";
import { useNavigate } from "react-router-dom";
import "./table.css"

export const Table = ({limit, data, columns, checkbox, navigateTo }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  return (
    <Box m="0px">
      <Box
        m="20px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "black",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            borderRadius: "10px 10px 0px 0px",
            height: "50px",
            padding: "20px 0",
            color: "white",
          },
          hideRightSeparator: {
            "& > .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#fff",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white",
            borderRadius: "0px 0px 10px 10px",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-footerContainer >div": {
            borderTop: "none",
            color: "white",
            backgroundColor: colors.blueAccent[700],
          },

          "& .css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row": {
            backgroundColor: "#F9ECFF",
            marginTop: "5px",
            cursor: "pointer",
            borderRadius: "10px",
          },
          "& .MuiDataGrid-columnHeaders .MuiCheckbox-root": {
            color: `white`,
          },
          "& .MuiTablePagination-selectLabel": {
            color: `white`,
            marginBottom: `3px`,
          },
          "& .MuiTablePagination-displayedRows": {
            color: `white`,
            margin: `0px`,
          },
          "& .MuiIconButton-sizeSmall": {
            color: "white",
          },
        }}
      >
        <DataGrid
          key={data?.id || data?._id}
          checkboxSelection={checkbox}
          pageSize={limit||20}
          // onRowClick={(e) => navigate(`${navigateTo}/${e.row.id}`)}
          rows={data || mockDataInvoices}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="button-cotainer">
      <button className="button-yellow" onClick={() => navigate(-1)}>
        <BiArrowBack />
        <span>|</span>
        Back
      </button>
    </div>
  );
};



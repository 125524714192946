import React, { useEffect, useState } from "react";
import { Offcanvas, Row } from "react-bootstrap";
import { IoIosMail, IoMdNotifications } from "react-icons/io";
import { NavbarStyled } from "./NavbarStyles";
import user from "assets/image/userDummy.jpeg";
import "./Navbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { CollegeStudentSidePanel } from "contants/CollegeStudentSidePanel";
import { AiFillHome, AiOutlineMenu } from "react-icons/ai";
import logo from "assets/image/logo.svg";
import { toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { GetStudentsDetails } from "../../axios/apis";
import Cookies from "js-cookie";

export const NavbarCollegeStudent = ({ updateprofile }) => {
  const location = useLocation();
  const name = Cookies.get("name");
  const email = Cookies.get("email");
  const profileImage = Cookies.get("profileImage");
  var myDate = new Date();
  var hrs = myDate.getHours();
  const navigate = useNavigate()
  const [greet, setGreet] = useState("Good Morning");
  useEffect(() => {
    if (hrs < 12) setGreet("Good Morning");
    else if (hrs >= 12 && hrs <= 17) setGreet("Good Afternoon");
    else if (hrs >= 17 && hrs <= 24) setGreet("Good Evening");
  }, []);
  const [userDetail, setUserDetail] = useState({})
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Logout = () => {
    const yes = window.confirm("Are you sure you want to logout?");
    if (yes) {
      Cookies.remove("token");
      Cookies.remove("role");
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("profileImg");
      Cookies.remove("collegeID");
      Cookies.remove("userDetails");
      Cookies.remove("profile");
      Cookies.remove("loginType");
      Cookies.remove("profileImage");
      Cookies.remove("company");
      Cookies.remove("isPurchased");
      navigate("/");
    }
  };
  const GetUser = async () => {
    try {
      const res = await GetStudentsDetails();
      console.log(res);
      setUserDetail(res?.data?.data);
      res?.data?.data?.course?.map((course, i) => {
        if (course.courseID == "6343c15fe33839d145810f31") {
           Cookies.set("isPurchased", "true")
           console.log("purchased")
        }
      })
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
    }
  };

  useEffect(() => {
    GetUser();
  }, [updateprofile]);
  return (
    <>
      <NavbarStyled>
        <div className="nav-sec-1 d-none-968">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h6 className="nav-head">Hi, {userDetail?.firstname}</h6>
              {/* <p className="nav-para d-flex align-items-center">
                Trial Account
              </p> */}
            </div>
            {/* <div className="remaining-interview">
              <p className="nav-para ">Interviews Remaining - 1/1</p>
            </div> */}
          </div>
          <Row>
            <div className="d-flex align-items-center">
              <IoIosMail size={24} />
              <p className="nav-para">{userDetail?.email}</p>
            </div>
          </Row>
        </div>
        <div className="nav-sec-1 d-flex-968">
          <AiOutlineMenu onClick={handleShow} className="c-pointer" size={30} />
          <Offcanvas className="sidebar-968 " show={show} onHide={handleClose}>
            <Offcanvas.Header className="py-1">
              <div className="m-auto">

                <img style={{ width: "160px" }} src={logo} />
              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="px-3 py-4">
                <div className="min-height-sidebar">
                  {CollegeStudentSidePanel.slice(0, 5).map((item) => (
                    <NavLink
                      onClick={handleClose}

                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`
                        d-flex align-items-center py-2 wrapper__sidebar`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <hr />
                  {CollegeStudentSidePanel.slice(5, 8).map((item) => (
                    <NavLink
                      onClick={handleClose}

                      to={item.path}
                      className="sidebar__link"
                      key={item.id}
                    >
                      <div
                        className={`d-flex align-items-center py-2 wrapper__sidebar`}
                      >
                        {item.icon}
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      </div>
                    </NavLink>
                  ))}
                  <a className="sidebar__link" href="https://viosa.in/">
                    <div
                      className="d-flex align-items-center py-2 wrapper__sidebar

                  cursor-pointer"
                    >
                      <AiFillHome />
                      <h6 className="sidebar__heading px-2 mt-2">Go To Dashboard</h6>
                    </div>

                  </a>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <div className="d-flex justify-content-evenly align-items-center nav-sec-2">
          {/* <div className="nav-level">
            <h6>Level 1</h6>{" "}
          </div> */}
          <NavLink to={"/college-student/notifications"} className={`nav-notification ${location.pathname === "/notifications" ? "active" : ""}`}>
            <IoMdNotifications size={26} />
          </NavLink>
          <div className="user-img-cover">
            <img className="user-img" src={userDetail?.profileImage || user} alt="user" />
          </div>
        </div>
      </NavbarStyled>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { BoxStyle, DashboardBoxStyle, YellowHeading } from "components/common/CommonStyles";
import { PracticeCardData } from "../data";
import "./interviewee.css";

import PracticeInterviewCard from "./PracticeInterviewCard";
import { GetAllInterviewCorporate, GetIntervieweeDetails } from "../../../axios/apis";
import { Form, Spinner } from "react-bootstrap";
import Loading from "components/common/Loading";
import { AiOutlineSearch } from "react-icons/ai";
import { MDBPagination, MDBPaginationItem, MDBPaginationLink } from "mdb-react-ui-kit";

export const JobInterview = () => {
  const [interview, setInterView] = useState([]);
  const [allinterview, setAllInterView] = useState([]);
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState("")
  const [attemptedInterview, setAttemptedInterview] = useState([])
  const [page, setPage] = useState(1)
  const [totalpages, setTotalpages] = useState(1)
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40]
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllInterviewCorporate({
        page: page,
        limit: 10,
        title: "",
      })
      setInterView(response.data.interviews)
      setTotalpages(response.data.totalpages)

      setAllInterView(response.data.interviews);
      setLoading(false);

    } catch (error) {
      console.log(error)
      setLoading(false);

    }
  }
  const GetDetails = async () => {
    setLoading(true);
    try {
      const response = await GetIntervieweeDetails()
      const ids = [];
      response?.data?.message?.interviewResults?.map((data) => {
        ids.push(data?._id)
      })
      setAttemptedInterview(ids)
      setLoading(false);

    } catch (error) {
      console.log(error)
      setLoading(false);

    }
  }

  useEffect(() => {
    GetAllInterviews()
  }, [page])

  useEffect(() => {
    GetDetails()
  }, [])

  const searchInterview = async () => {
    if (search.length) {
      const response = await GetAllInterviewCorporate({
        page: 1,
        limit: 10,
        title: search,
      })
      setInterView(response.data.interviews)
      setAllInterView(response.data.interviews);
    } else {
      const response = await GetAllInterviewCorporate({
        page: page,
        limit: 10,
        title: "",
      })
      setInterView(response.data.interviews)
      setAllInterView(response.data.interviews);
    }
  }

  const handleSearch = (e) => {
    setSearch(e.target.value)
    // if (searched === "") {
    //   setInterView(allinterview)
    // }
    // else {
    //   const searchInterview = allinterview.filter(interview => interview.jobtitle.toLowerCase().includes(searched.toLowerCase()))
    //   setInterView(searchInterview)
    // }
  }

  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container my-0">
            <div className="row">
              <YellowHeading className="my-2">Job Interviews</YellowHeading>
              <div className="px-2 mb-3">

                <Form.Control
                  className="form-input"
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchInterview()
                    }
                  }}
                />
                <div className="eye-position">
                  <AiOutlineSearch
                    onClick={searchInterview}

                    className="search-icon pe-1"
                    size={28}
                  />
                </div>
              </div>
              <div className="mt-0">
                <div>
                  {interview.length > 0 ? (interview.map((item, index) => (
                    <PracticeInterviewCard isAttempted={attemptedInterview.includes(item?._id)} key={index} item={item} />))) : (<>
                      <h6>Currently No Job Interview.</h6>
                    </>)
                  }
                </div>
              </div>
              <MDBPagination center className='mb-0'>

                {arr.slice(0, totalpages).map((data, i) => (

                  <MDBPaginationItem active={page === i + 1}>
                    <MDBPaginationLink onClick={() => setPage(i + 1)} href='#'>{i + 1}</MDBPaginationLink>
                  </MDBPaginationItem>
                ))}

              </MDBPagination>
            </div>
          </div>
        </DashboardBoxStyle>)}</>
  );
};
